<template>
  <div>
    <a-row :gutter="12">
      <a-col span="8">
        <div class="count-box">
          <div>已售产品总数</div>
          <div class="count-value">{{ data.total | formatBigNumber }}</div>
        </div>
      </a-col>
      <a-col span="8">
        <div class="count-box">
          <div>本月产品销售总数</div>
          <div class="count-value">{{ data.this_month_count | formatBigNumber }}</div>
        </div>
      </a-col>
      <a-col span="8">
        <div class="count-box">
          <div>本年产品销售总数</div>
          <div class="count-value">{{ data.this_year_count | formatBigNumber }}</div>
        </div>
      </a-col>
    </a-row>
    <product-total-trend-chart class="margin-top-20" />
    <product-column-chart class="margin-top-20" />
  </div>
</template>

<script>
import { findProductStatisticCount } from '@/api/product_statistic'
import ProductColumnChart from '@/views/package_product_data/products/ProductColumnChart'
import ProductTotalTrendChart from '@/views/package_product_data/products/ProductTotalTrendChart'
export default {
  name: 'ProductIndex',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ProductTotalTrendChart,
    // eslint-disable-next-line vue/no-unused-components
    ProductColumnChart
  },
  data() {
    return {
      loadingCount: true,
      loadingChart: true,
      total: 0,
      thisDayCount: 0,
      thisMonthCount: 0,
      thisYearCount: 0,
      mostMonthCount: 0,
      mostYearCount: 0,

      data: {}
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loadingCount = true
      findProductStatisticCount().then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loadingCount = false
      })
    }
  }
}
</script>
<style lang="less" scoped>
.count-box {
  background-color: #169bd5;
  height: 80px;
  border-radius: 3px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;

  .count-value {
    color: @orange;
    font-weight: bold;
  }
}

.margin-top-20 {
  margin-top: 20px;
}

</style>
