import request from '@/utils/request'

// 查询套餐数量统计
export function findPackageStatisticCount() {
  return request({
    url: '/package_statistics/count',
    method: 'get'
  })
}

// 查询套餐统计
export function findPackageStatistics() {
  return request({
    url: '/package_statistics',
    method: 'get'
  })
}

// 查询套餐统计趋势
export function findPackageStatisticsTrend(params) {
  return request({
    url: '/package_statistics/trend',
    method: 'get',
    params: params
  })
}
