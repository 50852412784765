<template>
  <div>
    <div style="margin: 20px">
      <a-input-search placeholder="请输入产品名称" style="width: 200px" @search="onSearch" />
    </div>
    <div class="trend-chart se-padding-top-20px se-padding-left-10px">
      <a-spin
        :spinning="loading"
        class="se-spin-stretch"
      >
        <div class="se-padding-bottom-10px se-padding-right-10px">
          <div
            ref="costStatisticChart"
            style="height: 250px;"
          />
        </div>
      </a-spin>
    </div>
    <!--  展示单一套餐折线图  -->
    <product-single-trend-chart
      v-if="isShowTrendModal"
      :visible.sync="isShowTrendModal"
      :product-id="showProductId"
      :product-name="showProductName"
    />
  </div>
</template>

<script>
import { findProductStatistics } from '@/api/product_statistic'
import ProductSingleTrendChart from '@/views/package_product_data/products/ProductSingleTrendChart'
import 'echarts/lib/component/dataZoom'

export default {
  name: 'ProductColumnChart',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ProductSingleTrendChart
  },
  data() {
    this.handleWindowResize = this.$lodash.throttle(this.handleWindowResize, 1000)
    return {
      chart: null,
      statistics: [],
      loading: true,
      value: [],
      isShowTrendModal: false,
      showProductId: 0, // 需要展示折线图的产品id
      showProductName: '', // 需要展示折线图的产品名字
      productName: ''
    }
  },
  created() {
    window.addEventListener('resize', this.handleWindowResize)
  },
  mounted() {
    this.fetchData()
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  computed: {
    chartOption() {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: (params) => {
            let relVal = params[0].name
            for (let i = 0; i < params.length; i++) {
              relVal += '<br/>' + params[i].marker + params[i].name +
                '&nbsp;&nbsp;' + params[i].value
            }
            return relVal
          }
        },
        grid: {
          x: 70,
          y: 0,
          x2: 20,
          y2: 0,
          // left: '2%',
          // right: '2%'
          containLabel: true
        },
        legend: {},
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            fontSize: 12
          }
        },
        series: [],
        dataZoom: [
          {
            type: 'slider',
            realtime: true,
            startValue: 0,
            endValue: 5,
            width: '1',
            height: '100%',
            yAxisIndex: [0], // 控制y轴滚动
            fillerColor: 'rgb(68,103,103)', // 滚动条颜色
            borderColor: 'rgb(255,255,255)',
            backgroundColor: '#cfcfcf', // 两边未选中的滑动条区域的颜色
            handleSize: 0, // 两边手柄尺寸
            showDataShadow: false, // 是否显示数据阴影 默认auto
            showDetail: false, // 拖拽时是否展示滚动条两侧的文字
            top: '1%',
            right: '5'
          },
          {
            type: 'inside',
            startValue: 0,
            endValue: 2,
            yAxisIndex: [0],
            zoomOnMouseWheel: false, // 关闭滚轮缩放
            moveOnMouseWheel: true, // 开启滚轮平移
            moveOnMouseMove: true
          }]
      }
    }
  },
  methods: {
    handleWindowResize() {
      this.chart && this.chart.resize()
    },

    setSeries() {
      // 从全局色中获取一个颜色
      this.chartOption.series.push({
        type: 'bar',
        data: this.$lodash.map(this.statistics, 'count'),
        ids: this.$lodash.map(this.statistics, 'product_id')
      })
    },

    drawChart() {
      this.chart ||= this.$echarts.init(this.$refs.costStatisticChart, 'echarts_dark')
      this.chartOption.yAxis.data = this.$lodash.map(this.statistics, 'name')
      this.chartOption.series = []
      this.setSeries()
      this.chart.setOption(this.chartOption, true)
      this.chart.on('click', (param) => {
        this.isShowTrendModal = true
        this.showProductId = this.chartOption.series[0].ids[param.dataIndex]
        this.showProductName = param.name
      })
    },

    fetchData(name) {
      this.loading = true
      findProductStatistics({ product_name: name }).then((res) => {
        if (res.code === 0) {
          this.statistics = res.data
          this.drawChart()
        }
        this.loading = false
      })
    },

    onSearch(value) {
      this.fetchData(value)
    }
  }
}
</script>

<style lang="less" scoped>
.trend-chart {
  border: 1px solid rgba(16, 144, 223, 0.2);
  height: 100%;
}
</style>
