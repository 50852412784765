import request from '@/utils/request'

// 查询产品数量统计
export function findProductStatisticCount() {
  return request({
    url: '/product_statistics/count',
    method: 'get'
  })
}

// 查询产品统计
export function findProductStatistics(params) {
  return request({
    url: '/product_statistics',
    method: 'get',
    params: params
  })
}

// 查询产品统计趋势
export function findProductStatisticsTrend(params) {
  return request({
    url: '/product_statistics/trend',
    method: 'get',
    params: params
  })
}

// 查询产品销售数据
export function findProductSaleStatistics(params) {
  return request({
    url: '/product_statistics/sale',
    method: 'get',
    params: params
  })
}

// 下载产品销售数据
export function downloadProductSaleStatistics(params) {
  return request({
    url: '/product_statistics/sale/download',
    method: 'get',
    params: params
  })
}

// 查询产品销售详情数据
export function findProductSaleStatisticsDetail(params) {
  return request({
    url: '/product_statistics/sale/detail',
    method: 'get',
    params: params
  })
}
