<template>
  <div>
    <a-row :gutter="12">
      <a-col span="8">
        <div class="count-box">
          <div>已售套餐总数</div>
          <div class="count-value">{{ data.total | formatBigNumber }}</div>
        </div>
      </a-col>
      <a-col span="8">
        <div class="count-box">
          <div>本月套餐销售总数</div>
          <div class="count-value">{{ data.this_month_count | formatBigNumber }}</div>
        </div>
      </a-col>
      <a-col span="8">
        <div class="count-box">
          <div>本年套餐销售总数</div>
          <div class="count-value">{{ data.this_year_count | formatBigNumber }}</div>
        </div>
      </a-col>
    </a-row>

    <package-total-trend-chart class="margin-top-20" />
    <package-column-chart class="margin-top-20" />
  </div>
</template>

<script>
import PackageTotalTrendChart from '@/views/package_product_data/packages/PackageTotalTrendChart'
import PackageColumnChart from '@/views/package_product_data/packages/PackageColumnChart'
import { findPackageStatisticCount } from '@/api/package_statistic'
export default {
  name: 'PackageIndex',
  components: {
    PackageTotalTrendChart,
    PackageColumnChart
  },
  data() {
    return {
      loadingCount: true,
      loadingChart: true,
      total: 0,
      thisDayCount: 0,
      thisMonthCount: 0,
      thisYearCount: 0,
      mostMonthCount: 0,
      mostYearCount: 0,

      data: {}
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loadingCount = true
      findPackageStatisticCount().then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loadingCount = false
      })
    }
  }
}
</script>
<style lang="less" scoped>
.count-box {
  background-color: @primary-color;
  height: 80px;
  border-radius: 3px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;

  .count-value {
    color: @orange;
    font-weight: bold;
  }
}
.margin-top-20 {
  margin-top: 20px;
}
</style>
