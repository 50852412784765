<template>
  <div class="box">
    <a-row :gutter="[16,16]">
      <a-col span="12">
        <div class="custom-card-box">
          <div class="box-height">
            <package />
          </div>
          <div>套餐销售数量排名</div>
        </div>
      </a-col>

      <a-col span="12">
        <div class="custom-card-box">
          <div class="box-height">
            <product />
            <div>产品销售数量排名</div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>

import Package from '@/views/package_product_data/packages/index'
import Product from '@/views/package_product_data/products/index'
export default {
  name: 'PackageProductData',
  components: {
    Package,
    // eslint-disable-next-line vue/no-unused-components
    Product
  }
}
</script>

<style lang="less" scoped>
.box {
  text-align: center
}
</style>
