<template>
  <div>
    <div class="custom-padding-bottom-10px custom-flex custom-flex-row custom-justify-between">
      <span class="title">套餐销售趋势</span>
      <div>
        <a-range-picker
          @change="handleChange"
          :value="value"
          v-if="timeType === 'day'"
          style="width: 250px"
        />
      </div>
      <div>
        <span>
          <a-radio-group
            v-model="timeType"
            @change="handleChangeTimeType"
            button-style="solid"
          >
            <a-radio-button value="day">
              日
            </a-radio-button>
            <a-radio-button value="month">
              月
            </a-radio-button>
            <a-radio-button value="year">
              年
            </a-radio-button>
          </a-radio-group>
        </span>
      </div>

    </div>
    <package-trend-chart :data="{time_type: timeType, date_begin: startTime, date_end: endTime}" />
  </div>
</template>

<script>
import { formatYearMonthDay } from '@/utils/time'
import PackageTrendChart from '@/views/package_product_data/packages/PackageTrendChart'

export default {
  name: 'PackageTotalTrendChart',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    PackageTrendChart
  },
  data() {
    return {
      chart: null,
      tagStatistics: [],
      timeType: 'day',
      loading: true,
      isShowChart: true,
      datePickerValue: undefined,
      startTime: '',
      endTime: '',
      value: []
    }
  },
  methods: {
    handleChangeTimeType(e) {
      this.$nextTick()
      this.timeType = e.target.value
    },

    handleChange(value) {
      this.value = value
      if (this.timeType === 'day' && value !== '') {
        this.startTime = formatYearMonthDay(value[0])
        this.endTime = formatYearMonthDay(value[1])
      } else {
        this.startTime = ''
        this.endTime = ''
      }
    }
  }
}
</script>
<style lang="less" scoped>
.title {
  color: @primary-color;
  margin-right: 10px;
}
</style>
